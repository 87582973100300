<template>
  <div>
    <b-card no-body>
      <v-table
        ref="acts-table"
        :path="`/cars/acts?populate=driver,sheet,car,carGroup,carPlan&all=${showAll}`"
        :fields="carActTable"
        :filter="{ ...filterForm, search }"
        sort="-date_start"
        table-sm
        hide-create-button
        hide-page-size
      >
        <template #before-table>
          <div class="m-2 no-print">
            <div class="row">
              <div class="col">
                <b-form-group
                  label="Водитель"
                >
                  <b-form-input
                    @input="onDriverInputHandler"
                  />
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  label="Гос.Номер"
                >
                  <b-form-input
                    @input="onNumberInputTimeout"
                  />
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  label="С даты"
                >
                  <b-form-datepicker
                    :value="filterForm.date__gte"
                    start-weekday="1"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    @input="onDateStartInputHandler"
                  />
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  label="По дату"
                >
                  <b-form-datepicker
                    :value="filterForm.date__lte"
                    start-weekday="1"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    @input="onDateEndInputHandler"
                  />
                </b-form-group>
              </div>
              <div class="col-2 d-flex justify-content-center">
                <b-form-checkbox
                  v-model="showAll"
                  value="1"
                  unchecked-value="0"
                  class="mb-1 align-self-end"
                >
                  Показать все
                </b-form-checkbox>
              </div>
              <!--
              <div class="col-1 d-flex justify-content-end">
                <b-button
                  variant="primary"
                  class="mb-1 align-self-end"
                >
                  <feather-icon icon="SearchIcon"/>
                </b-button>
              </div>
              -->
            </div>
          </div>
        </template>
        <template #cell(serial)="data">
          <v-table-serial-column
            :data="data"
          />
        </template>
        <template #cell(actions)="{ item }">
          <span></span>
          <v-table-actions-column
            @view="onViewActionHandler(item)"
            @edit="onEditActionHandler(item)"
            @delete="onDeleteActionHandler(item)"
          >
            <template #beforeButtons>
              <span
                class="text-info cursor-pointer mr-1"
                @click="onPrintActionHandler(item)"
              >
                <feather-icon icon="PrinterIcon"/>
              </span>
            </template>
          </v-table-actions-column>
        </template>
      </v-table>
    </b-card>
    <b-modal
      ref="act-modal"
      title="Акт приема передачи"
      :hide-footer="actEdit === null"
      @hidden="actView=actEdit=null"
      @ok.prevent="onModalSaveHandler"
    >
      <data-view
        v-if="actView"
        :data="actViewData"
      />
      <car-act-form
        v-if="actEdit"
        :value="actEdit"
        action="edit"
        @input="actEdit = $event"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BCard, BFormCheckbox, BFormDatepicker, BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
import VTable, { VTableActionsColumn, VTableSerialColumn, } from '@/views/components/vtable'
import DataView from '@/views/components/DataView.vue'
import { carActTable, } from '@/views/cars/cars_use'
import CarActForm from '@/views/cars/components/CarActForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CarActForm,
    BCard,
    BModal,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    VTable,
    VTableSerialColumn,
    VTableActionsColumn,
    DataView,
  },
  data() {
    return {
      carActTable,
      actView: null,
      actEdit: null,
      filterForm: {
        driver: '',
        number: '',
        date__gte: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        date__lte: moment()
          .format('YYYY-MM-DD'),
      },
      showAll: 0,
    }
  },
  computed: {
    actViewData() {
      if (this.actView === null) {
        return []
      }
      return [
        {
          label: 'ID',
          value: this.actView.id,
        },
        {
          label: 'Водитель',
          value: `${this.actView.driver.last_name} ${this.actView.driver.first_name} ${this.actView.driver.middle_name}`,
        },
        {
          label: 'Авто',
          value: `${this.actView.car.model} ${this.actView.car.mark} ${this.actView.car.number}`,
        },
        {
          label: 'Пробег',
          value: `${this.actView.mileage_start} / ${this.actView.car.mileage_end ?? '-'}`,
        },
        {
          label: 'Дата выезда',
          value: this.actView.date_start ? moment(this.actView.date_start).format('DD.MM.YY HH:mm') : '-',
        },
        {
          label: 'Дата заезда',
          value: this.actView.date_end ? moment(this.actView.date_end).format('DD.MM.YY HH:mm') : '-',
        },
        {
          label: 'Комментарий',
          value: this.actView.comment ?? '-',
        },
        {
          label: 'Статус',
          value: this.actView.status === 'opened' ? 'Открыт' : 'Закрыт',
        },
      ]
    },
    search() {
      return this.$store.getters['app/search']
    },
  },
  methods: {
    onDeleteActionHandler(item) {
      this.$axios.delete(`/cars/acts/${item.id}`)
        .then(() => {
          this.$refs['acts-table'].refresh()
        })
    },
    onViewActionHandler(item) {
      this.actView = item
      this.$refs['act-modal'].show()
    },
    onEditActionHandler(item) {
      this.actEdit = {
        ...item,
        date_start: item.date_start ? item.date_start.slice(0, 10) : null,
        date_end: item.date_end ? item.date_end.slice(0, 10) : null,
        date_start_time: item.date_start ? item.date_start.slice(11, 16) : null,
        date_end_time: item.date_end ? item.date_end.slice(11, 16) : null,
      }
      console.log(this.actEdit)
      this.$refs['act-modal'].show()
    },
    onPrintActionHandler(item) {
      this.$axios.post('/print/car-act', { id: [item.id] }, { responseType: 'blob' })
    },
    onDriverInputHandler(value) {
      clearTimeout(this.driverInputTimeout)
      this.driverInputTimeout = setTimeout(() => {
        this.filterForm.driver = value
      }, 600)
    },
    onNumberInputTimeout(value) {
      clearTimeout(this.numberInputTimeout)
      this.numberInputTimeout = setTimeout(() => {
        this.filterForm.number = value
      }, 600)
    },
    onDateStartInputHandler(value) {
      clearTimeout(this.dateStartTimeout)
      this.dateStartTimeout = setTimeout(() => {
        this.filterForm.date__gte = value
      }, 600)
    },
    onDateEndInputHandler(value) {
      clearTimeout(this.dateEndTimeout)
      this.dateEndTimeout = setTimeout(() => {
        this.filterForm.date__lte = value
      }, 600)
    },
    onModalSaveHandler() {
      const data = {
        ...this.actEdit,
        date_start: `${this.actEdit.date_start} ${this.actEdit.date_start_time}`,
      }
      if (!data.date_end) {
        data.date_end = moment().format('YYYY-MM-DD')
      }
      data.date_end = `${data.date_end} ${this.actEdit.date_end_time}`
      this.$axios.put(`/cars/acts/${this.actEdit.id}`, data)
        .then(() => {
          this.$refs['act-modal'].hide()
          this.$refs['acts-table'].refresh()
        })
    },
  },
}
</script>
